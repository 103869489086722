import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`*`}{`Todays wod is our 2nd Pre Open WOD of our in house competition!`}{`*`}</strong></p>
    <p><strong parentName="p">{`RX Division`}</strong></p>
    <p>{`10:00 AMRAP,`}</p>
    <p>{`10-1 reps each of:`}</p>
    <p>{`Power Cleans (135/95)`}</p>
    <p>{`T2B`}</p>
    <p>{`Front Squat`}</p>
    <p>{`Burpee Over Bar (2 foot take off and landing)`}</p>
    <p><em parentName="p">{`*`}{`if you complete the 1 rep round start at 1 and go back to 2, 3, etc.`}</em></p>
    <p><strong parentName="p">{`Scaled and Masters Divisions`}</strong></p>
    <p>{`10:00 AMRAP`}</p>
    <p>{`10-1 reps each of:`}</p>
    <p>{`Power Cleans (95/65)`}</p>
    <p>{`Hanging Straight Leg Raise (feet above parallel)`}</p>
    <p>{`Front Squat`}</p>
    <p>{`Burpee Over Bar (2 foot take off and landing)`}</p>
    <p>{`*`}{`*`}{`If time remains in class`}{`*`}{`*`}</p>
    <p>{`Front Squat 8-8-8\\@75% 1RM`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The next Strength Program will start today. For more information
email Eric at: Fallscitystrength\\@gmail.com.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Saturday, February 4th, we will have our 3rd Annual Sports
Nutrition seminar will Dawn from Sports Nutrition 2 Go in Cincinnati.
Metabolic Testing will be offered before and after the seminar as
well!`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      